<template>
    <router-view />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('base', {
            selectedProduct: 'selectedProduct',
        }),
    },
    methods: {
        ...mapActions('importacoes', {
            updateBaseData: 'updateBaseData',
        }),
    },
    created() {
        this.updateBaseData({
            selectedProduct: { ...this.selectedProduct },
        });
    },
};
</script>